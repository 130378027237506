/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ProofOfEfficiency,
  ProofOfEfficiencyInterface,
} from "../ProofOfEfficiency";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IPolygonZkEVMGlobalExitRoot",
        name: "_globalExitRootManager",
        type: "address",
      },
      {
        internalType: "contract IERC20Upgradeable",
        name: "_matic",
        type: "address",
      },
      {
        internalType: "contract IVerifierRollup",
        name: "_rollupVerifier",
        type: "address",
      },
      {
        internalType: "contract IPolygonZkEVMBridge",
        name: "_bridgeAddress",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "_chainID",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "_forkID",
        type: "uint64",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "BatchAlreadyVerified",
    type: "error",
  },
  {
    inputs: [],
    name: "BatchNotSequencedOrNotSequenceEnd",
    type: "error",
  },
  {
    inputs: [],
    name: "ExceedMaxVerifyBatches",
    type: "error",
  },
  {
    inputs: [],
    name: "FinalNumBatchBelowLastVerifiedBatch",
    type: "error",
  },
  {
    inputs: [],
    name: "FinalNumBatchDoesNotMatchPendingState",
    type: "error",
  },
  {
    inputs: [],
    name: "FinalPendingStateNumInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "ForceBatchTimeoutNotExpired",
    type: "error",
  },
  {
    inputs: [],
    name: "ForceBatchesOverflow",
    type: "error",
  },
  {
    inputs: [],
    name: "ForcedDataDoesNotMatch",
    type: "error",
  },
  {
    inputs: [],
    name: "GlobalExitRootNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "HaltTimeoutNotExpired",
    type: "error",
  },
  {
    inputs: [],
    name: "InitNumBatchAboveLastVerifiedBatch",
    type: "error",
  },
  {
    inputs: [],
    name: "InitNumBatchDoesNotMatchPendingState",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidProof",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidRangeBatchTimeTarget",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidRangeMultiplierBatchFee",
    type: "error",
  },
  {
    inputs: [],
    name: "NewAccInputHashDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "NewPendingStateTimeoutMustBeLower",
    type: "error",
  },
  {
    inputs: [],
    name: "NewTrustedAggregatorTimeoutMustBeLower",
    type: "error",
  },
  {
    inputs: [],
    name: "NotEnoughMaticAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "OldAccInputHashDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "OldStateRootDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyAdmin",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyEmergencyState",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyNotEmergencyState",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyPendingAdmin",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyTrustedAggregator",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyTrustedSequencer",
    type: "error",
  },
  {
    inputs: [],
    name: "PendingStateDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "PendingStateInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "PendingStateNotConsolidable",
    type: "error",
  },
  {
    inputs: [],
    name: "PendingStateTimeoutExceedHaltAggregationTimeout",
    type: "error",
  },
  {
    inputs: [],
    name: "SequenceZeroBatches",
    type: "error",
  },
  {
    inputs: [],
    name: "SequencedTimestampBelowForcedTimestamp",
    type: "error",
  },
  {
    inputs: [],
    name: "SequencedTimestampInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "StoredRootMustBeDifferentThanNewRoot",
    type: "error",
  },
  {
    inputs: [],
    name: "TransactionsLengthAboveMax",
    type: "error",
  },
  {
    inputs: [],
    name: "TrustedAggregatorTimeoutExceedHaltAggregationTimeout",
    type: "error",
  },
  {
    inputs: [],
    name: "TrustedAggregatorTimeoutNotExpired",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
    ],
    name: "AcceptAdminRole",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "stateRoot",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "uint64",
        name: "pendingStateNum",
        type: "uint64",
      },
    ],
    name: "ConsolidatePendingState",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "EmergencyStateActivated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "EmergencyStateDeactivated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "forceBatchNum",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "lastGlobalExitRoot",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "sequencer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "transactions",
        type: "bytes",
      },
    ],
    name: "ForceBatch",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "stateRoot",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "aggregator",
        type: "address",
      },
    ],
    name: "OverridePendingState",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "storedStateRoot",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "provedStateRoot",
        type: "bytes32",
      },
    ],
    name: "ProveNonDeterministicPendingState",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
    ],
    name: "SequenceBatches",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
    ],
    name: "SequenceForceBatches",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint16",
        name: "newMultiplierBatchFee",
        type: "uint16",
      },
    ],
    name: "SetMultiplierBatchFee",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "newPendingStateTimeout",
        type: "uint64",
      },
    ],
    name: "SetPendingStateTimeout",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newTrustedAggregator",
        type: "address",
      },
    ],
    name: "SetTrustedAggregator",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "newTrustedAggregatorTimeout",
        type: "uint64",
      },
    ],
    name: "SetTrustedAggregatorTimeout",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newTrustedSequencer",
        type: "address",
      },
    ],
    name: "SetTrustedSequencer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "newTrustedSequencerURL",
        type: "string",
      },
    ],
    name: "SetTrustedSequencerURL",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "newVerifyBatchTimeTarget",
        type: "uint64",
      },
    ],
    name: "SetVerifyBatchTimeTarget",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newPendingAdmin",
        type: "address",
      },
    ],
    name: "TransferAdminRole",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "forkID",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "string",
        name: "version",
        type: "string",
      },
    ],
    name: "UpdateZkEVMVersion",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "stateRoot",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "aggregator",
        type: "address",
      },
    ],
    name: "VerifyBatches",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint64",
        name: "numBatch",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "stateRoot",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "aggregator",
        type: "address",
      },
    ],
    name: "VerifyBatchesTrustedAggregator",
    type: "event",
  },
  {
    inputs: [],
    name: "acceptAdminRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "sequencedBatchNum",
        type: "uint64",
      },
    ],
    name: "activateEmergencyState",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "admin",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "batchFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    name: "batchNumToStateRoot",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bridgeAddress",
    outputs: [
      {
        internalType: "contract IPolygonZkEVMBridge",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "calculateRewardPerBatch",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "chainID",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "pendingStateNum",
        type: "uint64",
      },
    ],
    name: "consolidatePendingState",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "deactivateEmergencyState",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "transactions",
        type: "bytes",
      },
      {
        internalType: "uint256",
        name: "maticAmount",
        type: "uint256",
      },
    ],
    name: "forceBatch",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    name: "forcedBatches",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "forkID",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getCurrentBatchFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "initNumBatch",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "finalNewBatch",
        type: "uint64",
      },
      {
        internalType: "bytes32",
        name: "newLocalExitRoot",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "oldStateRoot",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "newStateRoot",
        type: "bytes32",
      },
    ],
    name: "getInputSnarkBytes",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getLastVerifiedBatch",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "globalExitRootManager",
    outputs: [
      {
        internalType: "contract IPolygonZkEVMGlobalExitRoot",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "admin",
            type: "address",
          },
          {
            internalType: "address",
            name: "trustedSequencer",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "pendingStateTimeout",
            type: "uint64",
          },
          {
            internalType: "address",
            name: "trustedAggregator",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "trustedAggregatorTimeout",
            type: "uint64",
          },
        ],
        internalType: "struct PolygonZkEVM.InitializePackedParameters",
        name: "initializePackedParameters",
        type: "tuple",
      },
      {
        internalType: "bytes32",
        name: "genesisRoot",
        type: "bytes32",
      },
      {
        internalType: "string",
        name: "_trustedSequencerURL",
        type: "string",
      },
      {
        internalType: "string",
        name: "_networkName",
        type: "string",
      },
      {
        internalType: "string",
        name: "_version",
        type: "string",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "isEmergencyState",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "pendingStateNum",
        type: "uint64",
      },
    ],
    name: "isPendingStateConsolidable",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastBatchSequenced",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastForceBatch",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastForceBatchSequenced",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastPendingState",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastPendingStateConsolidated",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastTimestamp",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lastVerifiedBatch",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "matic",
    outputs: [
      {
        internalType: "contract IERC20Upgradeable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "multiplierBatchFee",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "networkName",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "initPendingStateNum",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "finalPendingStateNum",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "initNumBatch",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "finalNewBatch",
        type: "uint64",
      },
      {
        internalType: "bytes32",
        name: "newLocalExitRoot",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "newStateRoot",
        type: "bytes32",
      },
      {
        internalType: "uint256[2]",
        name: "proofA",
        type: "uint256[2]",
      },
      {
        internalType: "uint256[2][2]",
        name: "proofB",
        type: "uint256[2][2]",
      },
      {
        internalType: "uint256[2]",
        name: "proofC",
        type: "uint256[2]",
      },
    ],
    name: "overridePendingState",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingAdmin",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingStateTimeout",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "pendingStateTransitions",
    outputs: [
      {
        internalType: "uint64",
        name: "timestamp",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "lastVerifiedBatch",
        type: "uint64",
      },
      {
        internalType: "bytes32",
        name: "exitRoot",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "stateRoot",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "initPendingStateNum",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "finalPendingStateNum",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "initNumBatch",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "finalNewBatch",
        type: "uint64",
      },
      {
        internalType: "bytes32",
        name: "newLocalExitRoot",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "newStateRoot",
        type: "bytes32",
      },
      {
        internalType: "uint256[2]",
        name: "proofA",
        type: "uint256[2]",
      },
      {
        internalType: "uint256[2][2]",
        name: "proofB",
        type: "uint256[2][2]",
      },
      {
        internalType: "uint256[2]",
        name: "proofC",
        type: "uint256[2]",
      },
    ],
    name: "proveNonDeterministicPendingState",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "rollupVerifier",
    outputs: [
      {
        internalType: "contract IVerifierRollup",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "transactions",
            type: "bytes",
          },
          {
            internalType: "bytes32",
            name: "globalExitRoot",
            type: "bytes32",
          },
          {
            internalType: "uint64",
            name: "timestamp",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "minForcedTimestamp",
            type: "uint64",
          },
        ],
        internalType: "struct PolygonZkEVM.BatchData[]",
        name: "batches",
        type: "tuple[]",
      },
      {
        internalType: "address",
        name: "feeRecipient",
        type: "address",
      },
    ],
    name: "sequenceBatches",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes",
            name: "transactions",
            type: "bytes",
          },
          {
            internalType: "bytes32",
            name: "globalExitRoot",
            type: "bytes32",
          },
          {
            internalType: "uint64",
            name: "minForcedTimestamp",
            type: "uint64",
          },
        ],
        internalType: "struct PolygonZkEVM.ForcedBatchData[]",
        name: "batches",
        type: "tuple[]",
      },
    ],
    name: "sequenceForceBatches",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    name: "sequencedBatches",
    outputs: [
      {
        internalType: "bytes32",
        name: "accInputHash",
        type: "bytes32",
      },
      {
        internalType: "uint64",
        name: "sequencedTimestamp",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "previousLastBatchSequenced",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "newMultiplierBatchFee",
        type: "uint16",
      },
    ],
    name: "setMultiplierBatchFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "newPendingStateTimeout",
        type: "uint64",
      },
    ],
    name: "setPendingStateTimeout",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newTrustedAggregator",
        type: "address",
      },
    ],
    name: "setTrustedAggregator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "newTrustedAggregatorTimeout",
        type: "uint64",
      },
    ],
    name: "setTrustedAggregatorTimeout",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newTrustedSequencer",
        type: "address",
      },
    ],
    name: "setTrustedSequencer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newTrustedSequencerURL",
        type: "string",
      },
    ],
    name: "setTrustedSequencerURL",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "newVerifyBatchTimeTarget",
        type: "uint64",
      },
    ],
    name: "setVerifyBatchTimeTarget",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newPendingAdmin",
        type: "address",
      },
    ],
    name: "transferAdminRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "trustedAggregator",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "trustedAggregatorTimeout",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "trustedSequencer",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "trustedSequencerURL",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "verifyBatchTimeTarget",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "pendingStateNum",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "initNumBatch",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "finalNewBatch",
        type: "uint64",
      },
      {
        internalType: "bytes32",
        name: "newLocalExitRoot",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "newStateRoot",
        type: "bytes32",
      },
      {
        internalType: "uint256[2]",
        name: "proofA",
        type: "uint256[2]",
      },
      {
        internalType: "uint256[2][2]",
        name: "proofB",
        type: "uint256[2][2]",
      },
      {
        internalType: "uint256[2]",
        name: "proofC",
        type: "uint256[2]",
      },
    ],
    name: "verifyBatches",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "pendingStateNum",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "initNumBatch",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "finalNewBatch",
        type: "uint64",
      },
      {
        internalType: "bytes32",
        name: "newLocalExitRoot",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "newStateRoot",
        type: "bytes32",
      },
      {
        internalType: "uint256[2]",
        name: "proofA",
        type: "uint256[2]",
      },
      {
        internalType: "uint256[2][2]",
        name: "proofB",
        type: "uint256[2][2]",
      },
      {
        internalType: "uint256[2]",
        name: "proofC",
        type: "uint256[2]",
      },
    ],
    name: "verifyBatchesTrustedAggregator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class ProofOfEfficiency__factory {
  static readonly abi = _abi;
  static createInterface(): ProofOfEfficiencyInterface {
    return new utils.Interface(_abi) as ProofOfEfficiencyInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ProofOfEfficiency {
    return new Contract(address, _abi, signerOrProvider) as ProofOfEfficiency;
  }
}
